import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import zhLang from 'element-ui/lib/locale/lang/zh-CN';
import locale from './assets/locale/cn';
import './assets/style/font.css';

Vue.config.productionTip = false
Vue.prototype.ENV_REQUEST = 'https://kanri.rabliss.com/'
// http://jjhttest.qifudaren.net 测试
// https://kanri.rabliss.com/  正式
Vue.use(ElementUI, {
  locale: { ...zhLang, ...locale }
});
Vue.use(router);
let vueThis = new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
export default vueThis;
