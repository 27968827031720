import Vue from 'vue'
//对应你要跳转的组件

import Router from 'vue-router'

Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/', name: '上陸する',
      component: () => import('../view/logon/index'),
    },
    // {
    //   path: '/logon', name: '上陸する',
    //   component: () => import('../view/logon/index'),
    // },
    {
      path: '/center', name: 'layout',
      component: () => import('../layout/index'),
    },
    {
      path: '/logon', name: '上陸する',
      component: () => import('../view/logon/index'),
    },
    {
      path: '/forgetPass', name: 'パスワードを忘れる',
      component: () => import('../view/forgetPass/index'),
    }
    
  ]
})
