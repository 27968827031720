export default {
    el: {
        table: {
            emptyText: 'データがありません',
            confirmFilter: '筛选',
            resetFilter: '重置',
            clearFilter: '全部',
            sumText: '合计'
        },
        pagination: {
            goto: '前往',
            pagesize: '件/ページ',
            total: '合計データ数 {total} 件',
            pageClassifier: '件'
        },
        datepicker: {
            now: '此刻',
            today: '今天',
            cancel: '取消',
            clear: '清空',
            confirm: '确定',
            selectDate: '选择日期',
            selectTime: '选择时间',
            startDate: '开始日期',
            startTime: '开始时间',
            endDate: '结束日期',
            endTime: '结束时间',
            prevYear: '前一年',
            nextYear: '后一年',
            prevMonth: '上个月',
            nextMonth: '下个月',
            year: '年',
            month:'月',
            month1: '1 月',
            month2: '2 月',
            month3: '3 月',
            month4: '4 月',
            month5: '5 月',
            month6: '6 月',
            month7: '7 月',
            month8: '8 月',
            month9: '9 月',
            month10: '10 月',
            month11: '11 月',
            month12: '12 月',
            // week: '周次',
            weeks: {
              sun: '日',
              mon: '月',
              tue: '火',
              wed: '水',
              thu: '木',
              fri: '金',
              sat: '土'
            },
            months: {
              jan: '一月',
              feb: '二月',
              mar: '三月',
              apr: '四月',
              may: '五月',
              jun: '六月',
              jul: '七月',
              aug: '八月',
              sep: '九月',
              oct: '十月',
              nov: '十一月',
              dec: '十二月'
            }
          },
    },

}