<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import '@/assets/style/comLess.less';
export default {
  name: "App",
  mounted() {
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是 hashchange事件
    window.addEventListener(
      "hashchange",
      () => {
        let currentPath = window.location.hash.slice(1);
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath);
        }
      },
      false
    );
  },
};
</script>

<style>
* {
  margin: 0px;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
